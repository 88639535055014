@import '../../assets/scss/_colors.scss';
@import '../../assets/scss/_typos.scss';
@import '../../assets/scss/_normalize.scss';
@import '../../assets/responsive/_responsive.scss';

.skills {
  flex-wrap: wrap;
  padding: 5rem 0 3rem 0;
  background-color: $buttonBlue;
  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    color: $LightBlue;
    font-family: josefin Sans, sans-serif;
  }
  .row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .skill {
    margin:  1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $MarinBlue;
    border-radius: 1rem;

    img {
      width: 50%;
      margin-top: 1rem;
    }
    p {
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 1rem;
      color: $white;
      font-family: josefin Sans, sans-serif;
    }
  }
  @include tablet {
    .swiper-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .swiper-slide {
      width: 300px;
      background-color: $MarinBlue;
      margin: 10px;
      border-radius: 10px;
    }
    .skill {
      width: 250px;
      height: 250px;
      img {
        width: 55%;
        height: 55%;
      }
      p {
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 1rem;
        color: $white;
        font-family: josefin Sans, sans-serif;
      }
    }
  }
}