@import '../../assets/scss/_colors.scss';
@import '../../assets/scss/_typos.scss';
@import '../../assets/scss/_normalize.scss';
@import '../../assets/responsive/_responsive.scss';


.politique {
  padding: 0 0;
  margin: 0;
  box-sizing: border-box;
  .politiqueText {
    text-align: center;
    padding-bottom: 50px;
    margin: 20px;
    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: left;
      font-family: Roboto sans-serif;
    }

    p {
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 1rem;
      text-align: left;
      font-family: Roboto sans-serif;
    }
  }
}