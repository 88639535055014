@import '../../assets/scss/_colors.scss';
@import '../../assets/scss/_typos.scss';
@import '../../assets/scss/_normalize.scss';
@import '../../assets/responsive/_responsive.scss';

.projets {
  padding: 100px 0;
  background-color: $MarinBlue;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    color: $LightBlue;
    font-family: josefin Sans, sans-serif;
  }

  .projetsCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;

    .projetsCard {
      width: 300px;
      perspective: 1000px;
      margin: 15px;
      flex: 1 1 300px;

      .cardInner {
        position: relative;
        width: 100%;
        height: 400px; /* La hauteur fixe est nécessaire pour éviter les débordements */
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        border-radius: 10px;

        &:hover {
          transform: rotatey(180deg);
        }

        .cardFront, .cardBack {
          position: absolute;
          width: 100%;
          height: 110%;
          backface-visibility: hidden;
          border-radius: 10px;
        }

        .cardFront {
          background-color: $white;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          img {
            width: 100%;
            height: 300px;
            object-fit: cover;
          }
        }

        .text {
          display: flex;
          justify-content: space-around;
          padding: 10px;
          background-color: $white;
          border-radius: 10px 10px 0 0;

          h3 {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 1rem;
            text-align: center;
            color: $MarinBlue;
            font-family: josefin Sans, sans-serif;
          }

          p {
            color: $MarinBlue;
            font-size: 1.2rem;
            text-align: center;
            font-family: josefin Sans, sans-serif;
          }

          .icons {
            display: flex;
            justify-content: center;
            align-items: center;

            a {
              color: $MarinBlue;
              font-size: 1.5rem;
              text-align: center;
              text-decoration: none;
              margin: 0 0.5rem;

              &:hover {
                color: $LightBlue;
              }
            }
          }
        }

        .tags {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          background-color: $white;
          border-radius: 0 0 10px 10px;
          padding: 10px 0;

          p {
            margin: 5px;
            padding: 5px;
            border: 1px solid $MarinBlue;
            border-radius: 5px;
            background-color: $white;
            color: $MarinBlue;
            font-size: 0.8rem;
            font-weight: 600;
            font-family: josefin Sans, sans-serif;
          }
        }

        .cardBack {
          background-color: $buttonBlue;
          color: $white;
          transform: rotateY(180deg);
          display: flex;
          flex-direction: column;
          border: $LightBlue solid 2px;
          .text{
            p{
                font-size: 1.2rem;
                text-align: center;
                font-family: josefin Sans, sans-serif;
                color : $MarinBlue;
            }
          }
          .desc{
            h4 {
              font-size: 1.2rem;
              font-weight: 600;
              margin-bottom: 1rem;
              text-align: center;
              color: $white;
              font-family: josefin Sans, sans-serif;
            }
            h3{
              font-size: 1.2rem;
              font-weight: 600;
              margin-bottom: 1rem;
              text-align: center;
              color: $MarinBlue;
              font-family: josefin Sans, sans-serif;
            }
            p {
              color: $white;
              font-size: 1.2rem;
              text-align: center;
              font-family: josefin Sans, sans-serif;
              padding: 0 1rem;
            }
          }
          }
        }
      }
    }
}