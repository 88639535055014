@import '../../assets/scss/_colors.scss';
@import '../../assets/scss/_typos.scss';
@import '../../assets/scss/_normalize.scss';
@import '../../assets/responsive/_responsive.scss';

.xp {
  background-color: $buttonBlue;
  padding: 10px 10px;

  h2 {
    color: $LightBlue;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    font-family: josefin Sans, sans-serif;
  }
}