@import '../../assets/scss/_colors.scss';
@import '../../assets/scss/_typos.scss';
@import '../../assets/scss/_normalize.scss';
@import '../../assets/responsive/_responsive.scss';

.header{
  padding: 150px 0;
  background-color: $MarinBlue;
  .headerText {
    text-align: center;
    margin:  0 10px;
    h1 {
      color: $LightBlue;
      font-size: 3rem;
      margin: 0 0 10px 0;
      padding: 10px 10px;
      font-weight: normal;
      font-family: Hind Vadodara, serif;
      display: inline-block;
      border: 2px solid $LightBlue;
      border-radius: 10px
    }

    h2 {
      color: $white;
      font-size: 2rem;
      font-weight: 500;
      margin: 0;
      padding: 0 0 30px 0;
      font-family: josefin Sans, sans-serif;

      span {
        color: $white;
      }
    }

    button {
      position: relative;
      width: 150px;
      height: 40px;
      line-height: 42px;
      padding: 0;
      border: none;
      color: $white;
      border-radius: 5px;
      background-color: $LightBlue;
      transition: all 0.5s ease-out;
      font-weight: bold;
      font-family: josefin Sans, sans-serif;
      &:hover {
        color: $LightBlue;
        background: transparent;
        box-shadow: none;
        border-radius:5px;
        cursor: pointer;
        scale: 1.1;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 2px;
        width: 0;
        background: $LightBlue;
        transition: 400ms ease all;
      }

      &:after {
        right: inherit;
        top: inherit;
        left: 0;
        bottom: 0;
      }

      &:hover:before,
      &:hover:after {
        width: 100%;
        transition: 800ms ease all;
      }
    }
  }
}

